import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class GetterService {

  demo = false;
  noParams: any;
  extras: any;
  app:any;
  pointsOfInterest: any;
  route:any;
  filteredCategories: any;
  routeHowFilter: any;
  routeTypeFilter: any;
  firstPointSelected: any;
  pdfs: any;
  menuPdfs: any;
  client: any;
  clientID: any;
  attributes: any;
  customRoutes: any;
  selectedPDFs: any;
  infoClient: any;
  weatherFake: any;
  filteredHome: any;
  idCicerone: any;
  redirect: any;
  closedPrompt = false;
  showBanner = false;
  deferredPrompt: any;
  allLocations: any;
  selectedLocation: any;
  isTotem: any;
  standby: any;
  mac: any;
  languages: any;
  slider: any;
  headerDesc: any;
  headerName: any;
  colorCorp: any;
  colorCorpStrong: any;
  selfie: any;
  imgRecommendedRoutes: any;
  imgRouteConfig: any;
  imgSelfie: any;
  streetmap: any;
  imgStreetMap: any;
  typePointsInterest: any;
  timeToEnterStandBy: any;
  logo: any;
  standBys: any;
  prefix: any;
  deviceID: any;
  rss: any;
  appStarted = false;
  updateMode = false;

  constructor(private router: Router, public navCtrl: NavController) {
    this.standby = false;
    this.isTotem = false;
    this.allLocations = [
      { id: 42, slider: [{ "url": "/assets/imgs/madrid.jpg", "time": "2000", "type": "img" }, { "url": "/assets/imgs/Madrid.mp4", "time": "145000", "type": "video" }], prefix: 'Madrid. ', location: { 'latitude': 40.416729, 'longitude': -3.703339 }},
      { id: 48, slider: [{ "url": "/assets/imgs/Islantilla.mp4", "time": "160000", "type": "video" }], prefix: 'Islantilla. ', location: { 'latitude': 37.2066126, 'longitude': -7.2340147 }},
      { id: 126, slider: [{ "url": "/assets/imgs/Valencia.mp4", "time": "50000", "type": "video" }], prefix: 'Valencia. ', location: { 'latitude': 39.4697500, 'longitude': -0.3773900 }}
    ];
  }

  public setExtras(data){
    const time = 1000 * 60 * 60 * 24;
    this.setWithExpiry('extras', data, time)
    this.extras = data;
  }

  public getExtras(){
    return this.extras ? this.extras : this.getWithExpiry('extras');
  }

  checkData(){
    if (!this.pointsOfInterest || !this.attributes || !this.pdfs || !this.menuPdfs || !this.customRoutes || !this.filteredCategories || !this.clientID 
        || !this.redirect || !this.selectedLocation){
      this.pointsOfInterest = this.pointsOfInterest ? this.pointsOfInterest : this.getWithExpiry('pointsOfInterest');
      this.attributes = this.attributes ? this.attributes : this.getWithExpiry('attributes');
      this.pdfs = this.pdfs ? this.pdfs : this.getWithExpiry('pdfs');
      this.menuPdfs = this.menuPdfs ? this.menuPdfs : this.getWithExpiry('menuPdfs');
      this.filteredCategories = this.filteredCategories ? this.filteredCategories : this.getWithExpiry('filteredCategories');
      this.customRoutes = this.customRoutes ? this.customRoutes : this.getWithExpiry('customRoutes');
      this.clientID = this.clientID ? this.clientID : this.getWithExpiry('clientID');
      this.redirect = this.redirect ? this.redirect : this.getWithExpiry('redirect');
      this.selectedLocation = this.selectedLocation ? this.selectedLocation : this.getWithExpiry('selectedLocation');
      this.weatherFake = this.weatherFake ? this.weatherFake : this.getWithExpiry('weatherFake');
      this.client = this.client ? this.client : this.getWithExpiry('client');
      this.isTotem = this.isTotem ? this.isTotem : this.getWithExpiry('isTotem');
      this.isTotem = this.isTotem ? this.isTotem : false;
      //this.filteredHome = this.filteredHome ? this.filteredHome : this.getWithExpiry('filteredHome');
      if (!this.pointsOfInterest || !this.attributes || !this.pdfs || !this.menuPdfs || !this.customRoutes || !this.filteredCategories || !this.clientID 
        || !this.redirect || !this.selectedLocation){
        window.location.href = window.location.origin;
      }
    }
  }

  checkNewData(){
    if (!this.appStarted){
      const url = localStorage.getItem('url')      
      window.location.href = url ? JSON.parse(url) : window.location.origin;
    }
  }

  setWithExpiry(key, value, ttl) {
    const now = new Date()
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }

  onImageError(e){
    e.target.src = "/assets/imgs/imageDefault.png";
  }

  animateCSS = (element, animation, prefix = 'animate__') =>
  new Promise((resolve, reject) => {
      const animationName = `${prefix}${animation}`;
      const node = document.querySelector(element);
      if (node){
          node.classList.add(`${prefix}animated`, animationName);
          function handleAnimationEnd(event) {
              event.stopPropagation();
              node.classList.remove(`${prefix}animated`, animationName);
              resolve('Animation ended');
          }
          node.addEventListener('animationend', handleAnimationEnd, {once: true});
      }
  });


}
   