import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'start', loadChildren: () => import('./pages/start/start.module').then(m => m.StartPageModule) },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
  { path: 'selfie', loadChildren: () => import('./pages/selfie/selfie.module').then(m => m.SelfiePageModule) },
  { path: 'howget', loadChildren: () => import('./pages/howget/howget.module').then( m => m.HowGetModule) },
  { path: 'menu', loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule) },
  { path: 'license', loadChildren: () => import('./pages/license/license.module').then( m => m.LicensePageModule) },
  { path: 'photo360', loadChildren: () => import('./pages/photo360/photo360.module').then( m => m.Photo360PageModule) },
  { path: 'details', loadChildren: () => import('./pages/details/details.module').then( m => m.DetailsPageModule) },
  { path: 'details-modal', loadChildren: () => import('./pages/details-modal/details-modal.module').then( m => m.DetailsModalPageModule) },
  { path: 'custom-route', loadChildren: () => import('./pages/custom-route/custom-route.module').then( m => m.CustomRoutePageModule) },
  { path: 'register-user', loadChildren: () => import('./pages/register-user/register-user.module').then( m => m.RegisterUserPageModule) },
  { path: 'modal-start-end', loadChildren: () => import('./pages/modal-start-end/modal-start-end.module').then( m => m.ModalStartEndPageModule) },
  { path: 'modal-add-point', loadChildren: () => import('./pages/modal-add-point/modal-add-point.module').then( m => m.ModalAddPointPageModule) },
  { path: 'modal-add-input', loadChildren: () => import('./pages/modal-add-input/modal-add-input.module').then( m => m.ModalAddInputPageModule) },
  { path: 'modal-pdf', loadChildren: () => import('./pages/modal-pdf/modal-pdf.module').then( m => m.ModalPDFPageModule) },
  { path: 'send-route', loadChildren: () => import('./pages/send-route/send-route.module').then( m => m.SendRoutePageModule) },  
  { path: 'send-route-pdf', loadChildren: () => import('./pages/send-route-pdf/send-route-pdf.module').then( m => m.SendRoutePDFPageModule) },  
  { path: 'recommended-routes', loadChildren: () => import('./pages/recommended_routes/recommended_routes.module').then( m => m.RecommendedRoutesPageModule) },
  { path: 'standby', loadChildren: () => import('./pages/standby/standby.module').then( m => m.StandByModule) },
  { path: 'streetmap', loadChildren: () => import('./pages/streetmap/streetmap.module').then( m => m.StreetMapModule) },
  { path: '', redirectTo: 'license', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
